var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('vue-element-loading',{attrs:{"active":_vm.appLoading,"is-full-screen":false}}),_c('v-snackbar',{attrs:{"color":"#00a972","right":"","timeout":_vm.timeout},model:{value:(_vm.showSnackBar),callback:function ($$v) {_vm.showSnackBar=$$v},expression:"showSnackBar"}},[_vm._v(" "+_vm._s(_vm.msg)+" "),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.showSnackBar = false}}},[_c('v-icon',{staticStyle:{"color":"white"}},[_vm._v("mdi-close")])],1)],1),_c('v-layout',{attrs:{"wrap":""}},[_c('v-flex',{attrs:{"xs12":"","md12":"","pa-0":""}},[_c('v-card',{attrs:{"id":"app_height","outlined":"","tile":""}},[_c('v-layout',{attrs:{"wrap":"","justify-space-around":""}},[_c('v-flex',{attrs:{"xs12":"","md12":"","pa-8":""}},[_c('v-layout',{attrs:{"wrap":""}},[_c('v-flex',{attrs:{"xs12":"","md12":"","text-center":""}},[_c('span',{staticStyle:{"font-size":"20px","font-weight":"500"}},[_vm._v("Application Queries List")])])],1),_c('v-layout',{attrs:{"wrap":"","px-2":"","justify-center":""}},[_c('v-flex',{attrs:{"xs12":"","md12":""}},[_c('v-card-title',[_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","color":"#26af82","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"dense":"","items":_vm.Queries,"search":_vm.search,"sort-desc":[false, true],"color":"#26af82","loading":_vm.appLoading,"loading-text":"Loading... Please wait"},scopedSlots:_vm._u([{key:"item.createddate",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.createddate))])]}},{key:"item.firstname",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.firstname)+" "+_vm._s(item.lastname))])]}},{key:"item.phone",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.phonePrefix)+" "+_vm._s(item.phone))])]}},{key:"item._id",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":'/ViewQuery/'+item._id}},[_c('v-btn',{attrs:{"small":"","text":"","color":"#26af82","block":""}},[_vm._v("View")])],1)]}}])})],1)],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }