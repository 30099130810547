<template>
  <div>
    <vue-element-loading :active="appLoading" :is-full-screen="false" />
    <v-snackbar v-model="showSnackBar" color="#00a972" right :timeout="timeout">
      {{msg}}
      <v-btn text @click="showSnackBar = false">
        <v-icon style="color:white;">mdi-close</v-icon>
      </v-btn>
    </v-snackbar>
    <v-layout wrap>
      <v-flex xs12 md12 pa-0>
        <v-card id="app_height" outlined tile>
          <v-layout wrap justify-space-around>
            <v-flex xs12 md12 pa-8>
              <v-layout wrap>
                <v-flex xs12 md12 text-center>
                  <span style="font-size:20px;font-weight:500;">Application Queries List</span>
                </v-flex>
              </v-layout>
              <v-layout wrap px-2 justify-center>
                <v-flex xs12 md12>
                  <v-card-title>
                    <v-spacer></v-spacer>
                    <v-text-field
                      v-model="search"
                      append-icon="mdi-magnify"
                      color="#26af82"
                      label="Search"
                      single-line
                      hide-details
                    ></v-text-field>
                  </v-card-title>

                  <v-data-table
                    :headers="headers"
                    dense
                    :items="Queries"
                    :search="search"
                    :sort-desc="[false, true]"
                    color="#26af82"
                    :loading="appLoading"
                    loading-text="Loading... Please wait"
                    class="elevation-1"
                  >
                    <template v-slot:item.createddate="{ item }">
                      <span>{{item.createddate}}</span>
                    </template>
                    <template v-slot:item.firstname="{ item }">
                      <span>{{item.firstname}} {{item.lastname}}</span>
                    </template>
                    <template v-slot:item.phone="{ item }">
                      <!-- <span>{{item.phonePrefix}}</span> -->
                      <span>{{item.phonePrefix}} {{item.phone}}</span>
                    </template>
                    <template v-slot:item._id="{ item }">
                      <router-link :to="'/ViewQuery/'+item._id">
                        <v-btn small text color="#26af82" block>View</v-btn>
                      </router-link>
                    </template>
                  </v-data-table>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
import VueElementLoading from "vue-element-loading";
export default {
  components: {
    VueElementLoading
  },
  data() {
    return {
      appLoading: false,
      showSnackBar: false,
      timeout: 10000,
      msg: null,
      search: null,
      headers: [
        { text: "Status", value: "status" },
        { text: "Date", value: "createddate" },
        { text: "Email Id", value: "email" },
        { text: "Phone", value: "phone" },
        { text: "Subject", value: "subject" },
        { text: "Action", align: "center", value: "_id" }
      ],
      Queries: []
    };
  },
  beforeMount() {
    this.getList();
  },
  methods: {
    getList() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/query/getlist",
        headers: {
          "x-auth-token": localStorage.getItem("token")
        }
      })
        .then(response => {
          this.appLoading = false;
          if (response.data.status) {
            this.Queries = response.data.data;
            for (var i = 0; i < this.Queries.length; i++) {
              var dt = new Date(this.Queries[i].createddate);
              dt = dt.toString();
              dt = dt.slice(4, 25);
              this.Queries[i].createddate = dt;
            }
          } else {
            this.msg = response.data.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch(err => {
          this.appLoading = false;
          this.msg = err.response.msg;
          this.showSnackBar = true;
        });
    }
  }
};
</script>
<style>
@media only screen and (min-width: 800px) {
  #app_height {
    min-height: 80vh;
    min-width: 80vw;
  }
}
</style>